import { answers, validLetters, words } from "./Constants";

export interface Guess {
  guess: string;
  locked: boolean;
}

export interface PuzzleState {
  solution: string;
  guesses: Guess[];
  solved?: boolean;
}

export type PuzzleBag = {
  [letter: number]: PuzzleState;
};

export type LetterState = "unknown" | "correct" | "displaced" | "incorrect";

export interface Letter {
  letter: string;
  state: LetterState;
}

export type LetterMap = { [letter: string]: Letter };

export interface Meta {
  expected: number;
  remaining: number;
  luck: number;
  skill: number;
}

export function getBlankPuzzleState(
  solution: string,
  guesses: number = 6
): PuzzleState {
  const state: PuzzleState = {
    guesses: [],
    solved: undefined,
    solution,
  };
  for (let guessIndex = 0; guessIndex < guesses; guessIndex++) {
    state.guesses.push({
      guess: "",
      locked: false,
    });
  }

  return state;
}

export class GuessTooShort extends Error {
  constructor(guess: string) {
    super(`${guess} is too short`);
  }
}

export class GuessNotInList extends Error {
  constructor(guess: string) {
    super(`${guess} is not in the list of acceptable words`);
  }
}

export const mutateGuess = (
  state: PuzzleState,
  setter: (guess: string) => string
): PuzzleState => {
  const guessIndex = getFocusedGuessIndex(state);
  if (guessIndex === undefined) return state;
  const copy = { ...state };
  copy.guesses = [...copy.guesses];
  copy.guesses[guessIndex] = { ...copy.guesses[guessIndex] };
  const guess = copy.guesses[guessIndex].guess;
  copy.guesses[guessIndex].guess = setter(guess).toLowerCase().substring(0, 5);
  return copy;
};

export const mutateGuessLock = (state: PuzzleState): PuzzleState => {
  const guessIndex = getFocusedGuessIndex(state);
  if (guessIndex === undefined) {
    return state;
  }
  const copy = { ...state };
  copy.guesses = [...copy.guesses];
  copy.guesses[guessIndex] = { ...copy.guesses[guessIndex] };
  const guess = copy.guesses[guessIndex].guess;

  if (guess.length < copy.solution.length) {
    throw new GuessTooShort(guess);
  }

  if (words.indexOf(guess) < 0) {
    throw new GuessNotInList(guess);
  }
  copy.guesses[guessIndex].locked = true;
  if (copy.solution === guess) {
    copy.solved = true;
  } else if (!copy.guesses.find((x) => !x.locked)) {
    copy.solved = false;
  }

  return copy;
};

export function getArrayFromWord(word: string, length: number) {
  return word.split("").concat(Array(length).fill(" ")).slice(0, length);
}

export function focused(
  state: PuzzleState,
  guessIndex: number,
  position: number
): boolean {
  if (state.solved) {
    return false;
  }
  const focusedGuess = state.guesses.findIndex(
    (guessIndex) => !guessIndex.locked
  );
  const focusedPosition = state.guesses[guessIndex].guess.length;
  return focusedGuess === guessIndex && focusedPosition === position;
}

export function getFocusedGuess(state: PuzzleState) {
  if (state.solved) return undefined;
  return state.guesses.find((guess) => !guess.locked);
}

export function getFocusedGuessIndex(state: PuzzleState) {
  if (state.solved) return undefined;
  return state.guesses.findIndex((guess) => !guess.locked);
}

export function replaceAt(str: string, position: number, chr: string) {
  const arr = str.split("");
  arr[position] = chr;
  return arr.join("");
}

export function getLetterStates(solution: string, guess: string): Letter[] {
  const letterStates: Letter[] = guess.split("").map((letter) => ({
    letter,
    state: "unknown",
  }));
  letterStates.forEach(({ letter }, position) => {
    const solutionLetter = solution[position];
    if (letter === solutionLetter) {
      letterStates[position].state = "correct";
      solution = replaceAt(solution, position, " ");
    }
  });
  letterStates.forEach(({ letter, state }, position) => {
    if (state !== "unknown") {
      return;
    }
    const solutionPosition = solution.indexOf(letter);
    if (solutionPosition >= 0) {
      letterStates[position].state = "displaced";
      solution = replaceAt(solution, solutionPosition, " ");
    }
  });
  letterStates.forEach(({ letter, state }, position) => {
    if (state === "unknown") {
      letterStates[position].state = "incorrect";
    }
  });
  return letterStates;
}

export function getLetterState(
  solution: string,
  guess: string,
  position: number
): Letter {
  return getLetterStates(solution, guess)[position];
}

export function getLetters(state: PuzzleState): LetterMap {
  const lockedGuesses = state.guesses
    .filter((guess) => guess.locked)
    .map((guess) => guess.guess);
  const letters: LetterMap = {};
  validLetters.forEach((letter) => {
    letters[letter] = {
      letter,
      state: "unknown",
    };
  });
  lockedGuesses.forEach((guess) => {
    getLetterStates(state.solution, guess).forEach(
      ({ letter, state }) => (letters[letter].state = state)
    );
  });
  return letters;
}

export function getRemaining(
  remaining: string[],
  solution: string,
  guess: string
) {
  const letters = getLetterStates(solution, guess);
  return remaining.filter((target) => {
    for (let position = 0; position < letters.length; position++) {
      const { letter, state } = letters[position];
      if (state !== "correct") continue;

      if (target[position] !== letter) return false;
      target = replaceAt(target, position, " ");
    }

    for (let position = 0; position < letters.length; position++) {
      const { letter, state } = letters[position];
      if (state !== "displaced") continue;

      const targetPosition = target.indexOf(letter);
      if (targetPosition === position || targetPosition < 0) {
        return false;
      }
      target = replaceAt(target, targetPosition, " ");
    }

    for (let position = 0; position < letters.length; position++) {
      const { letter, state } = letters[position];
      if (state !== "incorrect") continue;

      if (target.indexOf(letter) >= 0) {
        return false;
      }
    }
    return true;
  });
}

export function getExpectedRemaining(remaining: string[], guess: string) {
  return Math.floor(
    remaining
      .map((solution) => getRemaining(remaining, solution, guess).length)
      .reduce((a, b) => a + b, 0) / remaining.length
  );
}

export function getMeta(state: PuzzleState): Meta[] {
  const guesses = state.guesses
    .filter((guess) => guess.locked)
    .map((guess) => guess.guess);

  let remaining = answers;
  return guesses.map((guess, index) => {
    const expected = getExpectedRemaining(remaining, guess);
    remaining = getRemaining(remaining, state.solution, guess);
    const luck = Math.floor((100 * expected) / (remaining.length + expected));
    const skill = 0;
    return {
      expected,
      remaining: remaining.length,
      luck,
      skill,
    };
  });
}

export const getTodaysAnswerIndex = () => {
  const today = new Date();
  const date1 = new Date("6/20/21");
  const diffTime = Math.abs(today.getTime() - date1.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export function share(day: number, state: PuzzleState) {
  const letterStates = state.guesses
    .filter((guess) => guess.locked)
    .map((guess) => getLetterStates(state.solution, guess.guess));
  navigator.clipboard.writeText(
    `LinguaSanka ${day} ${state.solved ? letterStates.length : "X"}/${
      state.guesses.length
    }\n\n` +
      letterStates
        .map((guess) => {
          return (
            guess
              .map((letter) => {
                switch (letter.state) {
                  case "incorrect":
                    return "⬛";
                  case "correct":
                    return "🟩";
                  case "displaced":
                    return "🟨";
                  default:
                    return "  ";
                }
              })
              .join("") + "\n"
          );
        })
        .join("")
  );
}
