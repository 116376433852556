import React from "react";
import {
  getArrayFromWord,
  Guess as GuessType,
  getLetterState,
  Meta,
  PuzzleState,
} from "./State";

export interface Props {
  state: PuzzleState;
  guess: GuessType;
  meta?: Meta;
  focused: boolean;
}

export const Guess = ({ state, guess, meta, focused }: Props) => {
  return (
    <div className={"guess " + (focused ? "focused" : "")}>
      {getArrayFromWord(guess.guess, state.solution.length).map(
        (guessLetter, position) => (
          <span
            key={position}
            className={
              "letter " +
              (guess.locked
                ? getLetterState(state.solution, guess.guess, position).state
                : "unknown")
            }
          >
            {guessLetter || ""}
          </span>
        )
      )}
      {meta && guess.locked && (
        <div className="letter meta">
          <span className="expected">{meta.expected}</span>
          <span className="remaining">{meta.remaining}</span>
          <span className="skill">{meta.skill}</span>
          <span className="luck">{meta.luck}</span>
        </div>
      )}
    </div>
  );
};
