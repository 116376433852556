import React, { useEffect, useState } from "react";
import { answers } from "./Constants";
import { Board } from "./Board";
import { Keyboard } from "./Keyboard";
import {
  getBlankPuzzleState,
  mutateGuess,
  mutateGuessLock,
  PuzzleBag,
  PuzzleState,
  share,
} from "./State";

export interface Props {
  solutionIndex: number;
}

export function useLocalStorage<T>(
  keyName: string,
  defaultValue: T
): [T, (newValue: T) => void] {
  const [storedValue, setStoredValue] = React.useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);

      if (value) {
        return JSON.parse(value);
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = (newValue: T) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {}
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
}

export function Puzzle({ solutionIndex }: Props) {
  const [copied, setCopied] = useState(false);
  const [localState, setLocalState] = useLocalStorage("state", {} as PuzzleBag);
  const [meta, setMeta] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (localState[solutionIndex]) {
      setMeta(localState[solutionIndex].solved !== undefined);
      return;
    }

    const solution = answers[solutionIndex];
    const newState = getBlankPuzzleState(solution);
    const newLocalState = { ...localState };
    newLocalState[solutionIndex] = newState;
    setMeta(false);
    setCopied(false);
    setLocalState(newLocalState);
  }, [localState, setLocalState, solutionIndex]);

  if (!localState[solutionIndex]) {
    return <></>;
  }

  const state = localState[solutionIndex];
  const setState = (newState: PuzzleState) => {
    const newLocalState = { ...localState };
    newLocalState[solutionIndex] = newState;
    setLocalState(newLocalState);
  };

  const lock = () => {
    try {
      const newState = mutateGuessLock(state);
      if (newState.solved) {
        setTimeout(() => setMeta(true), 2000);
      }
      setState(newState);
      return true;
    } catch (error) {
      setError(true);
      setTimeout(() => setError(false), 3000);
      return false;
    }
  };

  const choose = (guess: string) => {
    setState(mutateGuess(state, () => guess));
  };

  const chooseLetter = (letter: string) => {
    setState(mutateGuess(state, (guess) => guess + letter));
  };

  const backspace = () => {
    setState(mutateGuess(state, (guess) => guess.slice(0, guess.length - 1)));
  };

  return (
    <div className={"puzzle " + (error ? "error" : "")}>
      {state.solved && (
        <button
          type="button"
          onClick={() => {
            share(solutionIndex, state);
            setCopied(true);
          }}
        >
          {copied ? "Copied!" : "Share"}
        </button>
      )}
      <Board state={state} choose={choose} lock={lock} showMeta={meta} />
      <Keyboard
        state={state}
        lock={lock}
        backspace={backspace}
        chooseLetter={chooseLetter}
      />
    </div>
  );
}
