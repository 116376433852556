import { Backspace, Enter, qwerty } from "./Constants";
import { getLetters, PuzzleState } from "./State";

export interface Props {
  state: PuzzleState;
  chooseLetter: (letter: string) => void;
  backspace: () => void;
  lock: () => void;
}

export const Keyboard = ({ state, chooseLetter, backspace, lock }: Props) => {
  const keyMap = getLetters(state);
  const handleKeyClick = (letter: string) => {
    switch (letter) {
      case Enter:
        lock();
        break;
      case Backspace:
        backspace();
        break;
      default:
        chooseLetter(letter);
        break;
    }
  };
  return (
    <div className="keyboard">
      {qwerty.map((key) => (
        <button
          key={key}
          data-key={key}
          className={"letter " + (keyMap[key]?.state || "unknown")}
          onClick={() => handleKeyClick(key)}
        >
          {key}
        </button>
      ))}
    </div>
  );
};
