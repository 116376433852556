import React, { useEffect, useRef } from "react";
import { Guess } from "./Guess";
import { getFocusedGuess, getMeta, PuzzleState } from "./State";

export interface Props {
  state: PuzzleState;
  choose: (letters: string) => void;
  lock: () => boolean;
  showMeta: boolean;
}

const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    const current = htmlElRef.current as any;
    current?.focus();
    current?.setSelectionRange(current?.value.length, current?.value.length);
  };

  return [htmlElRef, setFocus];
};

export const Board = ({ state, choose, lock, showMeta }: Props) => {
  const [inputRef, setInputFocus] = useFocus();
  const focusedGuess = getFocusedGuess(state);
  const meta = showMeta ? getMeta(state) : undefined;
  useEffect(() => {
    const interval = setInterval(() => {
      if (document?.activeElement?.nodeName !== "SELECT") {
        (setInputFocus as any)();
      }
    }, 100);
    return () => clearInterval(interval);
  }, [setInputFocus]);
  return (
    <>
      {focusedGuess && (
        <input
          type="text"
          ref={inputRef}
          autoFocus
          inputMode="none"
          value={focusedGuess?.guess}
          onKeyUp={(e) => e.key === "Enter" && lock()}
          onChange={(e) => choose(e.target.value)}
          spellCheck="false"
        />
      )}

      <div className={state.solved ? "board solved" : "board"}>
        {state.guesses.map((guess, guessIndex) => (
          <Guess
            state={state}
            guess={guess}
            meta={meta?.[guessIndex]}
            focused={guess === focusedGuess}
            key={guessIndex}
          />
        ))}
      </div>
    </>
  );
};
