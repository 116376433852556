import React, { useState } from "react";
import "./App.css";
import { Puzzle } from "./Puzzle";
import { getTodaysAnswerIndex } from "./State";

const todaysAnswerIndex = getTodaysAnswerIndex();

function App() {
  const [solutionIndex, setSolutionIndex] = useState(todaysAnswerIndex);

  return (
    <div className="App">
      <div className="title">
        <img src="logo.svg" />
        LinguaSanka
        <select
          value={solutionIndex.toString()}
          onChange={(e) => setSolutionIndex(parseInt(e.target.value))}
        >
          {Array.from(
            { length: todaysAnswerIndex + 1 },
            (item, index) => index
          ).map((index) => (
            <option key={index} value={index.toString()}>
              Day {index}
            </option>
          ))}
        </select>
      </div>
      <Puzzle solutionIndex={solutionIndex} />
    </div>
  );
}

export default App;
